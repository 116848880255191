*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, rgb(48, 48, 87), black);
}
.calculator{
    padding: 20px;
    border-radius: 10px;
    background-color: white;
}
form input{
    border: none;
    outline: 0;
    width: 60px;
    height: 60px;
    font-size: 16px;
    background-color: rgb(48, 48, 87);
    margin: 2px;
    border-radius: 10px;
    color: white;
    font-weight: bolder;
    cursor: pointer;
}
form input[type="button"]:hover{
    background-color: rgb(151, 151, 210);
}
.display{
    display: flex;
    justify-content: flex-end;
    margin: 5px 0px 15px 0px;
}
form .display input{
    text-align: right;
    flex:1;
    font-size: 40px;
    padding: 5px 10px;
    background-color: rgb(44, 43, 43);
}
.equal{
    width: 122px;
}